import React, { useState, useEffect } from "react";

const Leaderboard = ({ challengeDate }) => {
  const [leaderboard, setLeaderboard] = useState([]);

  // Load leaderboard data from localStorage when the component mounts
  useEffect(() => {
    const storedLeaderboard =
      JSON.parse(localStorage.getItem(challengeDate)) || [];
    setLeaderboard(storedLeaderboard);
  }, [challengeDate]);

  return (
    <div>
      <h2>Leaderboard for {challengeDate}</h2>
      <ol>
        {leaderboard.map((entry, index) => (
          <li key={index}>
            {entry.name}: {entry.score}s
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Leaderboard;
