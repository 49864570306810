import React from "react";
import Leaderboard from "./Leaderboard";

const LeaderboardPage = () => {
  // Calculate the current challenge date to pass to the Leaderboard
  const today = new Date();
  const challengeDate = today.toISOString().slice(0, 10); // e.g., "2024-12-10"

  return (
    <div className="LeaderboardPage">
      <h1>Leaderboard</h1>
      <Leaderboard challengeDate={challengeDate} />
    </div>
  );
};

export default LeaderboardPage;
