import React from "react";
import "./DailyChallenge.css";
import AdditionChallenge from "./challenges/AdditionChallenge";
import MultiplicationChallenge from "./challenges/MultiplicationChallenge";
import { EASY, MEDIUM, HARD } from "../constants"; // Import difficulty levels

const OFFSET = 0;

function DailyChallenge() {
  // Define available challenge types and difficulty levels
  const challengeTypes = ["addition"]; // Multiplication is removed for now
  const difficultyLevels = [EASY, MEDIUM, HARD];

  // Calculate Pacific midnight (UTC-8 or UTC-7 depending on DST)
  const today = new Date();
  const pacificOffset =
    today.getTimezoneOffset() +
    (new Date().getTimezoneOffset() === 420 ? 8 : 7) * 60;
  const pacificDate = new Date(today.getTime() - pacificOffset * 60000);

  const challengeIndex =
    (pacificDate.getDate() + OFFSET) % challengeTypes.length;
  const type = challengeTypes[challengeIndex];

  // Hash the date to get a consistent random difficulty
  const dayHash =
    today.getFullYear() * 10000 +
    (today.getMonth() + 1) * 100 +
    today.getDate();

  // 1/50 chance to set difficulty to null for a rare, "unknown" challenge
  const difficulty = difficultyLevels[dayHash % difficultyLevels.length];

  // Choose the correct component based on the selected challenge type
  let ChallengeComponent;
  switch (type) {
    case "addition":
      ChallengeComponent = AdditionChallenge;
      break;
    case "multiplication":
      ChallengeComponent = MultiplicationChallenge;
      break;
    default:
      ChallengeComponent = () => <p>Select a challenge type</p>;
  }

  return (
    <div className="DailyChallenge">
      <ChallengeComponent difficulty={difficulty} />
    </div>
  );
}

export default DailyChallenge;
