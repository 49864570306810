import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; // Import Link
import "./App.css";
import DailyChallenge from "./components/DailyChallenge";
import LeaderboardPage from "./components/LeaderboardPage"; // Import LeaderboardPage

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {/* Use Link for navigation */}
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <h1>Soroban Daily</h1>
          </Link>
        </header>
        <main className="App-main">
          <Routes>
            {/* Route for the Daily Challenge */}
            <Route path="/" element={<DailyChallenge />} />

            {/* Route for the Leaderboard */}
            <Route path="/leaderboard" element={<LeaderboardPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
