// Difficulty level constants
export const EASY = 1;
export const MEDIUM = 2;
export const HARD = 3;

// Function to convert difficulty number to string
export function getDifficultyString(difficulty) {
  switch (difficulty) {
    case EASY:
      return "Easy";
    case MEDIUM:
      return "Medium";
    case HARD:
      return "Hard";
    default:
      return "Easy";
  }
}
