import React, { useState, useEffect, useRef, useCallback } from "react";
import "./AdditionChallenge.css";
import confetti from "canvas-confetti";
import Cookies from "js-cookie";
import { EASY, MEDIUM, HARD, getDifficultyString } from "../../constants";

export const difficultySettings = {
  [EASY]: { numberOfNumbers: 5, maxNumber: 9, startingSpeed: 2000 },
  [MEDIUM]: { numberOfNumbers: 5, maxNumber: 20, startingSpeed: 2500 },
  [HARD]: { numberOfNumbers: 5, maxNumber: 100, startingSpeed: 3000 },
};

// Used for cheating and demonstrating the answer for development
const SHOW_ANSWER = false;

function setDifficultySettings(difficulty) {
  return difficultySettings[difficulty] || difficultySettings[EASY];
}

function AdditionChallenge({ difficulty }) {
  const settings = setDifficultySettings(difficulty);

  const [numbers, setNumbers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answer, setAnswer] = useState("");
  const [result, setResult] = useState(null);
  const [begin, setBegin] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [speed, setSpeed] = useState(settings.startingSpeed);
  const [countdownStage, setCountdownStage] = useState(null);
  const [playAllowed, setPlayAllowed] = useState(true);
  const [bestScore, setBestScore] = useState(null);

  const inputRef = useRef(null);

  // Check if the user is allowed to play based on cookies
  useEffect(() => {
    const gameBlocked = Cookies.get("additionChallengeBlocked");
    if (gameBlocked) {
      setPlayAllowed(false);
    }
  }, []);

  const generateNumbers = useCallback(() => {
    const generatedNumbers = [];
    for (let i = 0; i < settings.numberOfNumbers; i++) {
      let newNumber;
      do {
        newNumber = Math.floor(Math.random() * settings.maxNumber) + 1;
      } while (i > 0 && newNumber === generatedNumbers[i - 1]);
      generatedNumbers.push(newNumber);
    }
    setNumbers(generatedNumbers);
    const sum = generatedNumbers.reduce((acc, num) => acc + num, 0);
    setTotalSum(sum);
  }, [settings.numberOfNumbers, settings.maxNumber]);

  useEffect(() => {
    if (begin && currentIndex < numbers.length) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed);
      return () => clearInterval(interval);
    }
  }, [begin, currentIndex, numbers.length, speed]);

  useEffect(() => {
    if (countdownStage !== null) {
      if (countdownStage < 3) {
        const timeout = setTimeout(() => {
          setCountdownStage((prevStage) => prevStage + 1);
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        setBegin(true);
        setCountdownStage(null);
      }
    }
  }, [countdownStage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (answer !== "") {
      setSubmitted(true);
      if (parseInt(answer) === totalSum) {
        setResult(true);

        setBestScore((speed * numbers.length) / 1000);

        setSpeed((prevSpeed) => Math.round(prevSpeed * 0.9));

        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      } else {
        setResult(false);

        // Block the user if this is their first wrong attempt
        if (!Cookies.get("additionChallengeBlocked")) {
          // Calculate the time until midnight PST
          const now = new Date();

          // Convert current time to UTC and then subtract 8 hours for PST
          const utcHour = now.getUTCHours();
          const pstHour = (utcHour - 8 + 24) % 24; // PST is UTC-8

          // Calculate the milliseconds until midnight PST
          const msUntilMidnightPST =
            (24 - pstHour) * 60 * 60 * 1000 - // Hours remaining
            now.getUTCMinutes() * 60 * 1000 - // Minutes remaining
            now.getUTCSeconds() * 1000 - // Seconds remaining
            now.getUTCMilliseconds(); // Milliseconds remaining

          // Set the cookie to expire at the next midnight PST
          const expiresAtMidnightPST = new Date(
            now.getTime() + msUntilMidnightPST
          );

          Cookies.set("additionChallengeBlocked", "true", {
            expires: expiresAtMidnightPST,
          });
          setPlayAllowed(false);
        }
      }
    }
  };

  useEffect(() => {
    if (currentIndex === numbers.length && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentIndex, numbers.length]);

  const startChallenge = useCallback(() => {
    setCountdownStage(0);
    setCurrentIndex(0);
    setResult(null);
    setBegin(false);
    setAnswer("");
    setSubmitted(false);
    generateNumbers();
  }, [generateNumbers]);

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        bestScore
          ? `I got ${bestScore} seconds on the daily challenge at soroban-daily.com! Can you beat my score?`
          : `I couldn’t complete the soroban-daily.com challenge today. Can you?`
      )
      .then(() => {
        setCopied(true); // Update state to indicate the text was copied
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && submitted) {
        startChallenge();
      }
    };
    if (submitted) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [submitted, startChallenge]);

  if (!playAllowed && !submitted) {
    return (
      <div>
        <h1>Addition</h1>
        <p>You have already played the game. Please come back tomorrow!</p>
      </div>
    );
  }

  return (
    <div>
      {!begin && countdownStage === null && (
        <div>
          <h1>Addition</h1>
          <p className="explanation">
            Difficulty: {getDifficultyString(difficulty)}
          </p>
          <p className="instructions">
            Solve this addition problem by adding together a set of{" "}
            {settings.numberOfNumbers} numbers.
          </p>

          <button onClick={startChallenge}>Begin</button>
        </div>
      )}
      {countdownStage !== null && countdownStage < 3 && (
        <div>
          <p className="countdown">{["Ready", "Set", "Go!"][countdownStage]}</p>
        </div>
      )}
      {begin && (
        <div>
          {currentIndex < numbers.length ? (
            <p className="addition-numbers">{numbers[currentIndex]}</p>
          ) : (
            !submitted && <p className="countdown">End</p>
          )}
          <div aria-live="polite">
            {result === true && (
              <div>
                <p className="explanation">
                  Correct! You completed the challenge in {bestScore} seconds.
                </p>
                <p className="explanation">Next round will be 10% faster.</p>
              </div>
            )}
            {result === false && (
              <div>
                <p className="explanation">
                  Incorrect answer. The correct answer was {totalSum}.
                </p>
                {bestScore && (
                  <p className="explanation">
                    Your best score today was {bestScore} seconds.
                  </p>
                )}
                <p className="explanation">Come back tomorrow to try again!</p>
              </div>
            )}
          </div>
          {currentIndex === numbers.length && (
            <div className="input-container">
              <form onSubmit={handleSubmit}>
                <input
                  ref={inputRef}
                  type="number"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  autoFocus
                  disabled={submitted}
                />
              </form>
              {!submitted && <button onClick={handleSubmit}>Submit</button>}
              {submitted && result === false && (
                <button onClick={copyToClipboard}>
                  {copied ? "Copied!" : "Copy result"}
                </button>
              )}
              {submitted && result === true && (
                <button onClick={startChallenge}>Next Challenge</button>
              )}
            </div>
          )}
        </div>
      )}

      {SHOW_ANSWER && <div>{totalSum}</div>}
    </div>
  );
}

export default AdditionChallenge;
