import React, { useState, useEffect, useRef } from "react";
import "./MultiplicationChallenge.css";
import confetti from "canvas-confetti";

const NUMBER_OF_PAIRS = 5;

function MultiplicationChallenge() {
  const [pairs, setPairs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answer, setAnswer] = useState(""); // User input answer
  const [result, setResult] = useState(null); // Result message
  const [begin, setBegin] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(0); // Store correct answer for current pair
  const [startTime, setStartTime] = useState(null); // Track challenge start time
  const [elapsedTime, setElapsedTime] = useState(null); // Store elapsed time
  const [submitted, setSubmitted] = useState(false);

  // Create a ref for the input element
  const inputRef = useRef(null);

  // Generate random pairs of numbers once on mount
  useEffect(() => {
    const generatedPairs = Array.from({ length: NUMBER_OF_PAIRS }, () => [
      Math.floor(Math.random() * 100) + 1,
      Math.floor(Math.random() * 10) + 1,
    ]);
    setPairs(generatedPairs);
  }, []); // Only run on mount

  // Update correct answer when the currentIndex changes
  useEffect(() => {
    if (pairs.length > 0 && currentIndex < pairs.length) {
      const [first, second] = pairs[currentIndex];
      setCorrectAnswer(first * second);
    }
  }, [pairs, currentIndex]);

  // Function to handle answer submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (answer !== "") {
      if (parseInt(answer) === correctAnswer) {
        // Reset the false answer message
        setResult(null);
        if (currentIndex === pairs.length - 1) {
          // Challenge complete
          setResult(true);
          if (!submitted) {
            // Calculate elapsed time
            const endTime = new Date();
            const timeDiff = (endTime - startTime) / 1000; // Time in seconds
            setElapsedTime(timeDiff);
          }

          // Trigger confetti when the challenge is over
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });

          setAnswer("");
          setSubmitted(true);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          setAnswer(""); // Clear the answer input for the next question
        }
      } else {
        setResult(false);
      }
    }
  };

  // Focus the input field when all pairs are shown
  useEffect(() => {
    if (currentIndex === pairs.length && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentIndex, pairs.length]);

  // Function to start the challenge
  const startChallenge = () => {
    setBegin(true);
    setCurrentIndex(0);
    setResult(null);
    setAnswer("");
    setElapsedTime(null);
    setStartTime(new Date()); // Record the start time
  };

  console.log(currentIndex);
  return (
    <div>
      {!begin && (
        <div>
          <h1>Today's Challenge: Multiplication</h1>
          <p className="instructions">
            Solve this multiplication problem by answering a set of five
            multiplication questions. Click "Begin" to start. Each pair of
            numbers will appear on the screen. Enter your answer in the text box
            and press <strong>Enter</strong> to submit each answer.
          </p>
          <button onClick={startChallenge}>Begin</button>
        </div>
      )}

      {begin && (
        <div>
          {currentIndex < pairs.length ? (
            <p className="numbers">
              {pairs[currentIndex][0]} × {pairs[currentIndex][1]}
            </p>
          ) : (
            <p className="numbers">End</p>
          )}
          <div className="input-container">
            <form onSubmit={handleSubmit}>
              <input
                ref={inputRef}
                type="number"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                autoFocus
                disabled={currentIndex >= pairs.length} // Disable input if challenge is complete
              />
            </form>
          </div>
        </div>
      )}

      {result === true && (
        <p>Correct! You completed the challenge in {elapsedTime} seconds. </p>
      )}
      {result === false && <p>Incorrect answer. </p>}
    </div>
  );
}

export default MultiplicationChallenge;
